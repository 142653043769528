<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <Week @change_date="change_date" :outer_index="0" :begin_num="0" />
      <router-link
        :to="{
          path: '/online-inquiry',
        }"
      >
        <div class="else_box">
          <div class="online_box">
            <img
              src="../../assets/img/canlender.png"
              class="canlender"
              alt=""
              width="60"
            />
            <div class="content_box">
              <div class="content_title">挂不到号? 试试在线问诊</div>
              <div class="content_sub">线上问诊 送药上门</div>
            </div>
            <img
              src="../../assets/img/report-arrow.png"
              alt=""
              class="online_arrow"
              width="12"
            />
          </div>
        </div>
      </router-link>

      <div class="list_box">
        <div class="no-data" v-if="list.length === 0 && !loading">
          <img width="185" src="../../assets/img/no-data.png" />
          暂无数据
        </div>
        <van-list
          v-model="loading"
          loading-text=" "
          :finished="finished"
          @load="onLoad"
        >
          <transition-group appear>
            <div
              v-for="(i, index) in list"
              :key="index + '_no'"
              class="order-i"
              @click="jump_to(i)"
            >
              <div class="i-heard">
                <div class="head-img">
                  <img
                    v-if="i.avatar_url"
                    width="46"
                    height="46"
                    :src="i.avatar_url"
                  />
                  <img
                    v-else
                    width="46"
                    height="46"
                    src="../../assets/img/online/doctor.png"
                  />
                </div>
                <div class="heard-name">
                  <div>
                    {{ i.doctor_name
                    }}<span
                      style="
                        padding-left: 5px;
                        color: #565656;
                        font-weight: 400;
                      "
                      >{{ i.doctor_title_label }}</span
                    >
                  </div>
                  <span>{{ i.department_name }}</span>
                  <span>{{ i.hospital_name }}</span>
                </div>
                <div :class="['level', { level2: i.registed === 2 }]">
                  {{ i.registed === 1 ? "有号" : "无号" }}
                </div>
              </div>
              <div class="i-content">
                <div>
                  好评率:
                  <van-rate
                    readonly
                    gutter="2px"
                    :size="10"
                    v-model="i.score"
                    void-icon="star"
                    class="star"
                    color="#ffd21e"
                  />
                </div>
                <div>
                  就诊量: <span> {{ i.inquiry_count }} </span>
                </div>
              </div>
              <div class="desc">{{ i.desc }}</div>
              <div class="money"><span>¥</span>{{ format_money(i.price) }}</div>
            </div>
          </transition-group>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import Week from "./Week"
import { format_money, format_date } from "../../utils/format"
import { get_order_list } from "./service"
export default {
  data() {
    return {
      loading: false,
      list: [],
      finished: false,
      base_data: {
        hospital_id: null,
        department_code: null,
        date: null,
        page: 0,
        page_size: 10,
      },
      today: new Date(),
    }
  },
  computed: {},
  components: {
    Week,
  },
  watch: {},
  created() {
    let today = this.today.clone()
    this.base_data.date = today.setDate(today.getDate())
    this.base_data.hospital_id = this.$route.query.hospital_id
    this.base_data.department_code = this.$route.query.department_code
    document.title = this.$route.query.department_name

    this.search_list()
  },
  destroyed() {},
  methods: {
    format_date,
    format_money,
    change_date(v) {
      this.base_data.date = v.setDate(v.getDate())
      this.base_data.page = 0
      this.finished = false
      this.list = []
      this.search_list()
    },
    onLoad() {
      this.base_data.page++
    },
    async search_list() {
      this.loading = true
      try {
        const { data } = await get_order_list(this.base_data)
        this.list = this.list.concat(data.data)
        if (this.list.length >= data.total_elements) {
          this.finished = true
        }
      } finally {
        this.loading = false
      }
    },
    jump_to(item) {
      this.$router.push({
        path: "/doctor-page",
        query: {
          doctor_id: item.doctor_id,
          hospital_id: this.base_data.hospital_id,
          department_code: this.base_data.department_code,
          department_name: this.$route.query.department_name,
          // price: item.price,
        },
      })
    },
  },
}
</script>
<style scoped>
.g_main_content {
  padding-top: 2px;
  padding-bottom: 0;
}

.else_box {
  padding: 12px;
}

.online_box {
  height: 84px;
  background: #e8f8ff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.online_box::after {
  content: "";
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: linear-gradient(
    179deg,
    rgba(234, 247, 255, 0.05) 0%,
    #caf0ff 100%
  );
  position: absolute;
  right: -14px;
  top: -14px;
}

.content_box {
  flex: 1;
  padding-left: 12px;
}

.content_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #242938;
  padding-bottom: 4px;
}

.content_sub {
  font-size: 12px;
  line-height: 17px;
  color: #565656;
}

.list_box {
  padding: 0 12px;
  height: calc(100vh - 90px - 84px - 36px);
  overflow-y: auto;
}

.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  & img {
    margin-bottom: 28px;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    color: rgba(112, 112, 112, 0.5);
  }
}

.order-i {
  margin-bottom: 16px;
  background: #ffffff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 15px 8px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  & .i-heard {
    display: flex;
    align-items: center;
    & .heard-name {
      flex: 1;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
      padding-left: 12px;
      & > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #999;
        margin-right: 15px;
      }
    }
    & .head-img {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      background: #eee;
      & img {
        object-fit: cover;
      }
    }
    & .level {
      width: 44px;
      height: 23px;
      background: #1890ff;
      border-radius: 2px;
      font-size: 12px;
      color: #ffffff;
      text-align: center;
      line-height: 23px;
      &.level2 {
        color: #9c9c9c;
        background: #ebedf0;
      }
    }
  }
  & .i-content {
    font-size: 12px;
    color: rgba(153, 153, 153, 1);
    padding: 10px 0 7px;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    & > div {
      display: flex;
      align-items: center;
      & > span {
        padding: 0 6px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        color: rgba(0, 124, 233, 1);
      }
      & .star {
        padding: 0 6px 3px;
      }
    }
  }
  & .desc {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    line-height: 18px;
    color: #323233;
  }
  & .money {
    font-size: 18px;
    font-weight: 600;
    color: #ff6600;
    padding: 10px 0;
    & span {
      font-size: 12px;
      font-weight: 400;
      padding-right: 3px;
    }
  }
}

.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
.v-enter-active,
.v-leave-active {
  transition: all 1s;
}
</style>
