import api from '../../utils/api'
// 登录
export async function get_order_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_elements: 15,
  //         data: [
  //           {
  //             url: '',
  //             doctor_id: 1,
  //             doctor_name: '医生A',
  //             department_name: '呼吸内科',
  //             department_code: 123,
  //             doctor_title: 1,
  //             doctor_title_label: '主任医师',
  //             hospital_name: '罗湖医院',
  //             registed: 1,
  //             score: 4.5,
  //             inquiry_count: 20000,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             price: 200
  //           },
  //           {
  //             url: '',
  //             doctor_id: 1,
  //             doctor_name: '医生A',
  //             department_name: '呼吸内科',
  //             department_code: 123,
  //             doctor_title: 1,
  //             doctor_title_label: '主任医师',
  //             hospital_name: '罗湖医院',
  //             registed: 2,
  //             score: 5,
  //             inquiry_count: 20000,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             price: 200
  //           },
  //           {
  //             url: '',
  //             doctor_id: 1,
  //             doctor_name: '医生A',
  //             department_name: '呼吸内科',
  //             department_code: 123,
  //             doctor_title: 1,
  //             doctor_title_label: '主任医师',
  //             hospital_name: '罗湖医院',
  //             registed: 1,
  //             score: 4.5,
  //             inquiry_count: 20000,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             price: 200
  //           },
  //           {
  //             url: '',
  //             doctor_id: 2,
  //             doctor_name: '医生A',
  //             department_name: '呼吸内科',
  //             department_code: 123,
  //             doctor_title: 1,
  //             doctor_title_label: '主任医师',
  //             hospital_name: '罗湖医院',
  //             registed: 2,
  //             score: 3.5,
  //             inquiry_count: 20000,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             price: 200
  //           },
  //           {
  //             url: '',
  //             doctor_id: 1,
  //             doctor_name: '医生A',
  //             department_name: '呼吸内科',
  //             department_code: 123,
  //             doctor_title: 1,
  //             doctor_title_label: '主任医师',
  //             hospital_name: '罗湖医院',
  //             registed: 1,
  //             score: 4.5,
  //             inquiry_count: 20000,
  //             desc: '擅长: 妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌妇科良性、恶性肿瘤的诊断治疗，包括卵巢囊肿、子宫肌瘤、宫颈癌、子宫内膜癌',
  //             price: 200
  //           },
  //         ]
  //       }
  //       // }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/register/doctors', {params:payload})
}


